const ARKON_MEMORIES_AJAX_CONTROLLER_URL = location.origin + '/module/arkonmemories/ajax';

export default class LikeAction {
    init() {
        this.initLikeButtons();
        this.initLikesEvents();
    }

    initLikeButtons() {
        const likeButtons = document.querySelectorAll('.js-memory-like');

        for(const button of likeButtons) {
            button.addEventListener('click', () => {
                this.onLikeButtonClick(button);
            });
        }
    }

    onLikeButtonClick(button) {
        if(!button || !button.dataset || !button.dataset.memory) {
            return
        }

        this.likeButtonPost(button.dataset.memory);
    }

    likeButtonPost(id_memory) {
        $.ajax({
            type: "POST",
            url: ARKON_MEMORIES_AJAX_CONTROLLER_URL,

            data: {
                id_memory: id_memory,
                action: 'like'
            },

            success: (data) => {
                this.likeButtonPostSuccess(data);
            },
        });
    }

    likeButtonPostSuccess(data) {
        data = JSON.parse(data);
        
        if(!data.success) {
            return;
        }

        document.dispatchEvent(
            new CustomEvent('memory-liked', {detail: {
                memory: data.id_memory, 
                likes: data.likes
            }})
        );
    }

    initLikesEvents() {
        document.addEventListener('memory-liked', (e) => {
            if(!e.detail || !e.detail.memory || !e.detail.likes) {
                return;
            }

            this.onMemoryLike(e.detail.memory, e.detail.likes);
        });
    }

    onMemoryLike(id_memory, likes) {
        const likedMemory = document.getElementById('arkon-memory-' + id_memory);

        if(!likedMemory) {
            return;
        }
            
        likedMemory.classList.add('arkon-memory--liked');

        const likesCount = likedMemory.querySelector('.js-like-count');

        if(!likesCount) {
            return;
        }

        likesCount.innerHTML = likes;
    }
}